import React, { useEffect, useState } from "react";
import { ButtonWrapper, FlexWrapper } from "../PressSelect";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import ModalContainer from "../../components/Modal";
import { PinkTextStyle } from "../randomTopic";
import { useContext } from "react";
import { VideoContext } from "../../App";

const SubmitFormContainer = ({ values, onEmail, uploadProgress }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { singleData } = useContext(VideoContext);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    if (uploadProgress === 100) {
      setIsModalOpen(true);
    }
  };
  // handle close Modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleChangeTopic = () => {
    navigate("/select");
  };
  const handleGoBack = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (uploadProgress === 100) {
      setIsModalOpen(true);
    }
  }, [uploadProgress]);

  const formatNumberWithTwoDecimals = (number) => {
    return number.toFixed(1);
  };
  return (
    <FlexWrapper
      $direction="column"
      $align="center"
      $gap="2rem"
      $justify="center"
    >
      {singleData && (
        <FlexWrapper $gap="20px" $direction="column" $align="center">
          <PinkTextStyle>{singleData.name}</PinkTextStyle>
          <img src={singleData.image} alt="rainbow_img" />
          <PinkTextStyle>Tip</PinkTextStyle>
        </FlexWrapper>
      )}
      <FlexWrapper $direction="column" $gap="2rem" $width="100%">
        <ButtonWrapper $width="100%">
          <Button
            width="100%"
            btnText={
              values.video
                ? "Submit Video"
                : uploadProgress === 100
                ? "Continue"
                : uploadProgress > 0.0
                ? `${formatNumberWithTwoDecimals(uploadProgress)}%`
                : "Submit Video"
            }
            padding="16px 24px"
            handleEvent={handleOpenModal}
          />
        </ButtonWrapper>
        <ButtonWrapper $width="100%">
          <Button
            btnText={values.video ? "go back" : "Change Topic"}
            padding="16px 24px"
            handleEvent={values.video ? handleGoBack : handleChangeTopic}
            width="100%"
          />
        </ButtonWrapper>
      </FlexWrapper>
      {isModalOpen && (
        <ModalContainer
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onEmail={onEmail}
        />
      )}
    </FlexWrapper>
  );
};

export default SubmitFormContainer;
