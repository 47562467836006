import * as React from "react";
import { useRoutes } from "react-router-dom";
import PressStart from "../pages/PressStart";
import PressSelect from "../pages/PressSelect";
import SelectVideoTopic from "../pages/selectVideoTopic";
import RecordVideo from "../pages/recordVideo";
import UploadVideo from "../pages/uploadVideo";
import RandomTopic from "../pages/randomTopic";

const useProtectedRoutes = () => {
  const routes = useRoutes([
    { path: "/", exact: true, element: <PressStart /> },
    { path: "/select", exact: true, element: <PressSelect /> },
    { path: "/pitch-deck", exact: true, element: <RandomTopic /> },
    { path: "/select-video-topic", exact: true, element: <SelectVideoTopic /> },
    { path: "/record-video", exact: true, element: <RecordVideo /> },
    { path: "/upload-video", exact: true, element: <UploadVideo /> },
  ]);
  return routes;
};

export default useProtectedRoutes;
