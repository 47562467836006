import React from 'react';
import styled from 'styled-components';

const Button = ({ btnText, handleEvent, padding, color, width, type }) => {
    return (
        <ButtonStyled
            onClick={handleEvent}
            $width={width}
            $padding={padding}
            $color={color}
            type={type}
        >
            {btnText}
        </ButtonStyled>
    );
};

export default Button;

export const ButtonStyled = styled.button`
  padding: ${({ $padding }) => ($padding ? $padding : '24px')};
  text-transform: uppercase;
  border-radius: 70px;
  border: 1px dashed white;
  background: ${({ $color }) =>
        $color
            ? $color
            : 'linear-gradient(rgb(39, 1, 136) 0%, rgb(79, 3, 150) 28.11%, rgb(79, 3, 150) 73.4%, rgb(31, 0, 163) 100%), linear-gradient(0deg, rgb(255, 255, 255), rgb(255, 255, 255))'};
  width: ${({ $width }) => ($width ? $width : 'unset')};
  color: #ffffff;
  font-family: ArcadeClassic;
  font-size: ${({ $padding }) => ($padding ? '24px' : '32px')};
  font-weight: 400;
  line-height: ${({ $padding }) => ($padding ? '24px' : '32px')};
  letter-spacing: ${({ $padding }) => ($padding ? '0.15em' : '0.3em')};
  text-align: center;
  cursor: pointer;
  transition: background 0.5s, color 0.5s, transform 0.3s, border 0.5s;

  &:hover {
    background: linear-gradient(0deg, #d53124 20.41%, #f9f356 80.61%); /* Change background color on hover */
    color: #000; /* Change text color on hover */
    transform: scale(1.01); /* Scale the button slightly on hover */
    border: 1px dashed white; /* Change border on hover */
  }
`;
