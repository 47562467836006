
import useProtectedRoutes from './routes/useRoutes';

import './App.css';
import { createContext, useState } from 'react';

export const VideoContext = createContext({ isVideo: '', setIsVideo: null, randomData: '', setRandomData: null, singleData: '', setSingleData: {} })
function App() {
  const [isVideo, setIsVideo] = useState('')

  const [randomData, setRandomData] = useState([])
  const [singleData, setSingleData] = useState({})
  const routes = useProtectedRoutes()
  return (
    <>
      <VideoContext.Provider value={{ isVideo, setIsVideo, randomData, setRandomData, singleData, setSingleData }}>
        {routes}
      </VideoContext.Provider>
    </>
  );
}

export default App;
