import React from "react";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import { FlexWrapper } from "../PressSelect";

const DetailForm = ({ handleChange, values }) => {
  return (
    <FlexWrapper
      $direction="column"
      $width="100%"
      $justify="space-between"
      $gap="2rem"
    >
      <FlexWrapper $direction="column" $gap="0.5rem" $width="100%">
        <TextInput
          placeholderText="Enter Title Here.."
          name="title"
          value={values.title}
          handleChange={handleChange}
        />
        <TextArea
          name="description"
          value={values.description}
          handleChange={handleChange}
        />
      </FlexWrapper>
      <TextInput
        placeholderText="#hastags here..."
        placeholderColor="#8C30F5"
        name="tags"
        value={values.tags}
        handleChange={handleChange}
      />
    </FlexWrapper>
  );
};

export default DetailForm;
