/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { collection, addDoc } from "firebase/firestore";

import { FlexWrapper, HeaderStyled, SelectWrapper } from "../PressSelect";
import PageWrapper from "../../components/PageWrapper";
import { useFormik } from "formik";
import { VideoContext } from "../../App";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { GridStyled } from "./style";
import FileSelection from "./FileSelection";
import SubmitFormContainer from "./SubmitFormContainer";
import FormContainer from "./FormContainer";

const SelectVideoTopic = () => {
  const { setIsVideo } = useContext(VideoContext);
  const [email, setemil] = useState("");
  const [formValues, setFormValues] = useState({});
  const [videoURL, setVideoURL] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const initialValues = {
    email: email,
    title: "",
    description: "",
    tags: "",
    video: "" || null,
    time: "",
  };

  useEffect(() => {
    console.log(videoURL, "videoURL");
  }, [videoURL]);

  const handleFormSubmit = async (values, { resetForm }) => {
    let file = values?.video;
    const fileRef = ref(storage, `${file.name}`);
    const uploadTask = uploadBytesResumable(fileRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(progress, "progress");
        setUploadProgress(progress);
      },
      (error) => {
        console.log("error :!!!");
        setUploadProgress(0);
      },
      async () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setVideoURL(downloadURL);
          setFormValues(values);
          setUploadProgress(100);
        });
      }
    );
    resetForm({ values: "" || null });
    setIsVideo(null);
  };

  const addToFirebase = async (email) => {
    console.log("addToFirebase fun call");
    console.log("Email:", email);
    console.log("Title:", formValues?.title);
    console.log("Description:", formValues?.description);
    console.log("Tags:", formValues?.tags);
    console.log("Video URL:", videoURL);
    await addDoc(collection(db, "users"), {
      email: email || "",
      title: formValues?.title || "",
      description: formValues?.description || "",
      tags: formValues?.tags || "",
      video: videoURL || "",
      time: new Date(),
    });
  };

  const { values, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
  });
  useEffect(() => {
    setFormValues(values);
  }, [values]);

  return (
    <PageWrapper>
      <SelectWrapper>
        <HeaderStyled>Video topic</HeaderStyled>
        <FlexWrapper
          $direction="column"
          $justify="center"
          $align="center"
          $gap="2rem"
        ></FlexWrapper>

        <form onSubmit={handleSubmit}>
          <GridStyled>
            <FileSelection setFieldValue={setFieldValue} values={values} />

            <SubmitFormContainer
              onEmail={addToFirebase}
              values={values}
              uploadProgress={uploadProgress}
            />

            <FormContainer handleChange={handleChange} values={values} />
          </GridStyled>
        </form>
      </SelectWrapper>
    </PageWrapper>
  );
};

export default SelectVideoTopic;
