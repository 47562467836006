import React, { useRef, useState } from "react";
import {
  CustomControls,
  PlayPauseButton,
  Video,
  VideoContainer,
} from "../../pages/recordVideo";

const VideoComponent = ({ src, right }) => {
  const videoRef = useRef(null);
  const [isPLaying, setIsPLaying] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPLaying(true);
      } else {
        videoRef.current.pause();
        setIsPLaying(false);
      }
    }
  };

  return (
    <VideoContainer $right={right}>
      <Video ref={videoRef} src={src} />
      <CustomControls onClick={handlePlayPause}>
        <PlayPauseButton>
          <img
            src={
              !isPLaying ? "/assests/svgs/play.svg" : "/assests/svgs/pause.svg"
            }
            alt=""
          />
        </PlayPauseButton>
      </CustomControls>
    </VideoContainer>
  );
};

export default VideoComponent;
