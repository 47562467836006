/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import {
  ButtonWrapper,
  FlexWrapper,
  HeaderStyled,
  SelectWrapper,
} from "../PressSelect";
import Button from "../../components/Button";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { VideoContext } from "../../App";

const RandomTopic = () => {
  const { randomData, setRandomData, singleData, setSingleData } =
    useContext(VideoContext);

  const navigate = useNavigate();

  const handleContinue = () => {
    navigate("/select-video-topic");
  };

  const getRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * randomData?.length || 4);
    return randomNumber;
  };

  const getData = async () => {
    let topicArr = [];
    const querySnapshot = await getDocs(collection(db, "topics"));
    querySnapshot.forEach((doc) => {
      let singleDoc = doc.data();
      topicArr = [...topicArr, singleDoc];
    });
    setRandomData(topicArr);
  };

  const [showGif, setShowGif] = useState(true);
  const [loadingComplete, setLoadingComplete] = useState(false);

  let gifTimeout;
  let loadingCompleteTimeout;

  useEffect(() => {
    gifTimeout = setTimeout(() => {
      const randomNumber = getRandomNumber();
      const data = randomData[randomNumber];
      setShowGif(false);
      setLoadingComplete(true);
      setSingleData(data);
    }, 3000);

    return () => {
      clearTimeout(gifTimeout);
      clearTimeout(loadingCompleteTimeout);
    };
  }, []);

  useEffect(() => {
    if (loadingComplete && !singleData) {
      // After 3 seconds, select a random topic
      const randomNumber = getRandomNumber();
      const data = randomData[randomNumber];
      setSingleData(data);
    }
  }, [randomData, loadingComplete, singleData]);

  useEffect(() => {
    getData();
  }, []);

  const handleTryAgain = () => {
    clearTimeout(gifTimeout);
    clearTimeout(loadingCompleteTimeout);

    setShowGif(true);
    setLoadingComplete(false);
    setSingleData(null);

    gifTimeout = setTimeout(() => {
      setShowGif(false);
      setLoadingComplete(true);
    }, 3000);
  };

  return (
    <PageWrapper>
      <SelectWrapper>
        <FlexWrapper
          $direction="column"
          $justify="center"
          $align="center"
          $gap="2rem"
        >
          <HeaderStyled>Randomize Topic</HeaderStyled>

          {showGif && (
            <>
              <LoadingGif src="/assests/gif/random.gif" alt="Loading..." />
              <PinkTextStyle>Randomizing Topic...</PinkTextStyle>
            </>
          )}

          {loadingComplete && !!singleData?.name && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <PinkTextStyle>{singleData.name}</PinkTextStyle>
              <img src={singleData.image} alt="rainbow_img" />
              <PinkTextStyle>Tip</PinkTextStyle>
            </div>
          )}

          <FlexWrapper $width="100%" $justify="space-around" $gap="1rem">
            <ButtonWrapper>
              <Button btnText="try again?" handleEvent={handleTryAgain} />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button btnText="Continue" handleEvent={handleContinue} />
            </ButtonWrapper>
          </FlexWrapper>
        </FlexWrapper>
      </SelectWrapper>
    </PageWrapper>
  );
};

export default RandomTopic;

const LoadingGif = styled.img`
  display: block;
  width: 100px; // Adjust the width and height as needed
  height: 100px;
  margin: 0 auto;
`;

export const PinkTextStyle = styled.p`
  color: var(--retro-pink, #ffe7ff);
  text-align: center;
  text-shadow: 0px 0px 13px #f620e1;
  font-family: Inconsolata;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 131.23%; /* 39.369px */
  margin: 0;
`;
