import React from "react";
import styled from "styled-components";

const TextInput = ({ placeholderText, placeholderColor, name, value, handleChange }) => {
  return (
    <Input
      type='text'
      placeholder={placeholderText}
      $placeholderColor={placeholderColor}
      name={name}
      value={value}
      onChange={handleChange}
    />
  );
};

export default TextInput;

export const Input = styled.input`
  border: none;
  outline: none;
  color: ${({ $placeholderColor }) => ($placeholderColor ? "#FFCDFA" : "#ff98f5")};
  background: transparent;
  font-family: "Inconsolata";
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;

  ::placeholder {
    color: ${({ $placeholderColor }) => ($placeholderColor ? $placeholderColor : "#ff98f5")};
  }
`;
