import React from 'react'
import PageWrapper from '../../components/PageWrapper'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'

const PressStart = () => {
    const navigate = useNavigate()
    const handleClickEvent = () => {
        navigate('/select')
    }
    return (
        <PageWrapper noContainer>
            <Button btnText='press start' handleEvent={handleClickEvent} />
        </PageWrapper>
    )
}

export default PressStart