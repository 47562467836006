import React from "react";
import styled from "styled-components";

const TextArea = ({ name, value, handleChange }) => {
  return (
    <TextAreaInput
      placeholder='Enter Description here...'
      name={name}
      value={value}
      onChange={handleChange}
    />
  );
};

export default TextArea;

export const TextAreaInput = styled.textarea`
  border: none;
  outline: none;
  color: #ffe7ff;
  background: transparent;
  font-family: Inconsolata;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  resize: none;
  min-height: 20rem;
  ::placeholder {
    color: #8c30f5;
  }
`;
