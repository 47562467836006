import React, { useState } from "react";

import { Box, Modal } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  Body,
  BodyText,
  ButtonText,
  FlexBody,
  Header,
  Input,
  ModalButton,
  ModalButtonWrapper,
  Text,
} from "./style";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "948.25px",
  boxShadow: 24,
  background: "#f1e4ff",
  height: "610px",
  flexShrink: 0,
  padding: "12.46px 9.76px 0 20.64px",
};

const ModalContainer = ({ isOpen, onClose, onEmail }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const initialValues = {
    email: "",
  };
  const handleFormSubmit = async (values) => {
    onEmail(values.email);
    console.log(values.email);
    setIsSubmitted(true);
  };

  const { handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email("Please enter a valid email")
        .required("Please enter your email"),
    }),
    onSubmit: handleFormSubmit,
  });
  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Header>
          <span>
            <img src={"/assests/svgs/dialog.svg"} alt="Dialog" />
          </span>
          <div>
            <img src={"/assests/svgs/border.svg"} alt="border" width={"100%"} />
          </div>
          <button
            onClick={() => {
              onClose();
            }}
          >
            <div>
              <img src={"/assests/svgs/close.svg"} alt="close" />
            </div>
          </button>
        </Header>
        {isSubmitted ? (
          // Render images and text after submission

          <Body>
            <BodyText>
              <FlexBody $flexDirection="column" $gap="50px">
                <img
                  src={"/assests/svgs/cat.svg"}
                  alt="Cat"
                  style={{
                    width: "901px",
                    height: "130.28px",
                    top: "169.98px",
                    left: "393.38px",
                  }}
                />

                <Text>Congratulations! Your video has been uploaded</Text>
              </FlexBody>
            </BodyText>
            <ModalButtonWrapper>
              <ModalButton
                type="submit"
                onClick={() => {
                  onClose();
                }}
              >
                <ButtonText>OK</ButtonText>
              </ModalButton>
            </ModalButtonWrapper>
          </Body>
        ) : (
          // Render the form if not submitted
          <form onSubmit={handleSubmit}>
            <Body>
              <BodyText>
                <FlexBody $flexDirection="column" $gap="50px">
                  <Text>
                    Enter Email and we’ll help set up your
                    <br /> account (งツ)ว
                  </Text>
                  <Input
                    type="text"
                    id="make-account"
                    name="email"
                    onChange={handleChange}
                  />
                  {touched.email && errors.email ? <p>{errors.email}</p> : null}
                </FlexBody>
              </BodyText>
              <ModalButtonWrapper>
                <ModalButton type="submit">
                  <ButtonText>OK</ButtonText>
                </ModalButton>
              </ModalButtonWrapper>
            </Body>
          </form>
        )}
      </Box>
    </Modal>
  );
};

export default ModalContainer;
