import React from "react";
import PageWrapper from "../../components/PageWrapper";
import {
  ButtonWrapper,
  FlexWrapper,
  HeaderStyled,
  SelectWrapper,
} from "../PressSelect";
import {
  BlueBackgroundBlock,
  BrickBlueBlock,
  Content,
  FormContent,
  FrameImage,
} from "../selectVideoTopic/style";
import Button from "../../components/Button";
import DetailForm from "../selectVideoTopic/DetailForm";
import { useNavigate } from "react-router-dom";
import { PinkTextStyle } from "../randomTopic";

const UploadVideo = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/select-video-topic");
  };
  return (
    <PageWrapper>
      <SelectWrapper>
        <HeaderStyled>Video topic</HeaderStyled>
        <FlexWrapper $direction="row" $gap="2rem">
          <BrickBlueBlock>
            <FrameImage>
              <img src="/assests/images/frame1.png" alt="frame_img" />
            </FrameImage>
            <Content></Content>
          </BrickBlueBlock>
          <FlexWrapper
            $direction="column"
            $align="center"
            $gap="2rem"
            $justify="center"
          >
            <FlexWrapper $direction="column" $align="center">
              <PinkTextStyle>Pitch Deck</PinkTextStyle>
              <img src="/assests/svgs/rainbow.svg" alt="rainbow_img" />
              <PinkTextStyle>Tip</PinkTextStyle>
            </FlexWrapper>
            <FlexWrapper $direction="column" $gap="2rem" $width="100%">
              <ButtonWrapper>
                <Button
                  btnText="submit video"
                  padding="16px 24px"
                  handleEvent={() => console.log("pressed Console")}
                />
              </ButtonWrapper>
              <ButtonWrapper>
                <Button
                  btnText="go back"
                  padding="16px 24px"
                  handleEvent={handleGoBack}
                  width="100%"
                />
              </ButtonWrapper>
            </FlexWrapper>
          </FlexWrapper>
          <div>
            <BlueBackgroundBlock>
              <FrameImage>
                <img src="/assests/images/frame2.png" alt="frame_img" />
              </FrameImage>
              <FormContent>
                <DetailForm />
              </FormContent>
            </BlueBackgroundBlock>
          </div>
        </FlexWrapper>
      </SelectWrapper>
    </PageWrapper>
  );
};

export default UploadVideo;
