import styled from "styled-components";
import "./style.css";

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15.95px;
  & > span > img {
    width: 32.62px;
    height: 32.62px;
  }
  & > button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;
export const Body = styled.div`
  margin-top: 12px;
  width: 912px;
  height: 535px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 124.45px;
  stroke-width: 4.857px;
  stroke: #d6b1ff;
  border: inset;
  border-color: #d6b1ff;
  border-right-color: #fff;
  border-bottom-color: #fff;
`;
export const Text = styled.span`
  flex-shrink: 0;
  color: #000;
  font-family: "Inconsolata", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 131.23%; /* 39.369px */
`;
export const BodyText = styled.div`
  text-align: center;
  width: 860px;
  height: 119px;
  & > div {
    contain: content;
  }
`;
export const ModalButton = styled.button`
  width: 264.685px;
  height: 81.348px;
  cursor: pointer;
  flex-shrink: 0;
  border: outset;
  border-width: thick;
  border-width: thick;
  border-color: #d6b1ff;
  border-top-color: #fff;
  border-left-color: #fff;
  &:hover {
    background: #fff;
  }
`;
export const ButtonText = styled.span`
  color: #000;
  text-align: center;
  font-family: Inconsolata;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 131.23%; /* 39.369px */
`;
export const ModalButtonWrapper = styled.div`
  border-right: 4px solid black;
  border-bottom: 4px solid black;
`;
export const ButtonContainer = styled.div`
  display: flex;
  column-gap: 57.74px;
`;
export const Input = styled.input`
  width: 546px;
  height: 73px;
  flex-shrink: 0;
  background: var(--retro-white, #fff);
  align-self: center;
  border: none;
  border-top: 1px solid black;
  border-left: 1px solid black;
  font-size: 30px;
  font-family: "Inconsolata", sans-serif;
  outline: none;
`;
export const FlexBody = styled.div`
  display: flex;
  gap: ${(props) => props.$gap || "10px"};
  flex-direction: ${(props) => props.$flexDirection || "row"};
  & > button {
    border: none;
    cursor: pointer;
  }
`;
