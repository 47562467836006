/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useRef } from "react";
import {
  BrickBlueBlock,
  Content,
  FrameImage,
  FrameText,
  HiddenFileInput,
  IconBox,
  VideoFormatText,
} from "./style";
import { FlexWrapper } from "../PressSelect";
import { RecordSvg, UploadSvg } from "../../helper/svg";
import { useNavigate } from "react-router-dom";
import { VideoContext } from "../../App";
import styled from "styled-components";
import VideoComponent from "../../components/VIdeo";

const FileSelection = ({ setFieldValue, values }) => {
  const { isVideo, setIsVideo } = useContext(VideoContext);
  const navigate = useNavigate();
  const inputFileRef = useRef(null);

  // handle Icon CLick
  const handleIconClick = () => {
    inputFileRef.current.click();
  };

  const handleRecordVideo = () => {
    navigate("/record-video");
  };
  //Handle file Selection
  const handleFileSelection = (event) => {
    const file = event.target.files[0];

    if (file) {
      setFieldValue("video", file);
    }
  };
  // Video File Preview
  const SelectedFilePreview = useMemo(() => {
    return React.memo(({ file }) => {
      return (
        <div style={{ height: "100%", width: "100%", zIndex: "-1" }}>
          {file && file.type && file.type.startsWith("video/mp4") ? (
            <VideoComponent right src={URL.createObjectURL(file)} />
          ) : (
            <p>File type not supported for preview</p>
          )}
        </div>
      );
    });
  }, []);

  useEffect(() => {
    if (isVideo) {
      setFieldValue("video", isVideo);
    }
  }, [isVideo, setFieldValue, values.video]);
  return (
    <BrickBlueBlock>
      <FrameImage>
        <img src="/assests/images/frame1.png" alt="frame_img" />
      </FrameImage>

      <Content>
        <HiddenFileInput
          type="file"
          onChange={handleFileSelection}
          ref={inputFileRef}
        />
        {values.video ? (
          <SelectedFilePreview file={values.video} />
        ) : (
          <>
            <FrameText>
              Upload or Record - want to give record functionality
            </FrameText>
            <FlexWrapper $width="100%" $justify="space-evenly">
              <IconBox onClick={() => handleIconClick()}>
                <span>{UploadSvg}</span>
                <p>Upload</p>
              </IconBox>
              <IconBox onClick={handleRecordVideo}>
                <span>{RecordSvg}</span>
                <p>Record</p>
              </IconBox>
            </FlexWrapper>
            <VideoFormatText>Support mp4 video formats</VideoFormatText>
          </>
        )}
      </Content>
    </BrickBlueBlock>
  );
};

export default FileSelection;

export const SelectedVideo = styled.video`
  border-radius: 2.38rem 2.38rem 2.6rem 2.6rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
