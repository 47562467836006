import React from "react";
import { BlueBackgroundBlock, FormContent, FrameImage } from "./style";
import DetailForm from "./DetailForm";

const FormContainer = ({ values, handleChange }) => {
  return (
    <>
      <BlueBackgroundBlock>
        <FrameImage>
          <img src="/assests/images/frame2.png" alt="frame_img" />
        </FrameImage>
        <FormContent>
          <DetailForm values={values} handleChange={handleChange} />
        </FormContent>
      </BlueBackgroundBlock>
    </>
  );
};

export default FormContainer;
