import React from "react";
import PageWrapper from "../../components/PageWrapper";
import styled from "styled-components";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

const PressSelect = () => {
  const navigate = useNavigate();
  const handleClickEvent = () => {
    navigate("/pitch-deck");
  };
  return (
    <PageWrapper>
      <SelectWrapper>
        <FlexWrapper
          $direction="column"
          $justify="center"
          $align="center"
          $gap="2rem"
        >
          <HeaderStyled>Randomize</HeaderStyled>

          <HeaderStyled>Topic</HeaderStyled>
          <ButtonWrapper>
            <Button btnText="Press to Select" handleEvent={handleClickEvent} />
          </ButtonWrapper>
        </FlexWrapper>
      </SelectWrapper>
    </PageWrapper>
  );
};

export default PressSelect;

export const SelectWrapper = styled.div`
  @media screen and (max-width: 786px) {
    margin: 5rem 1rem 0 1rem;
  }
`;

export const FlexWrapper = styled.div`
  width: ${({ $width }) => ($width ? $width : "unset")};
  display: flex;
  flex-direction: ${({ $direction }) => ($direction ? $direction : "row")};
  gap: ${({ $gap }) => ($gap ? $gap : "0")};
  align-items: ${({ $align }) => ($align ? $align : "flex-start")};
  justify-content: ${({ $justify }) => ($justify ? $justify : "flex-start")};
  flex-wrap: wrap;

  & img {
    height: 150px;
    width: 150px;
    object-fit: contain;
    margin: auto;
  }
`;

export const HeaderStyled = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  font-family: ArcadeClassic;
  font-size: 63px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: 0.11em;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(0deg, #d53124 20.41%, #f9f356 80.61%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-shadow: -1px -1px black; */
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: max-content;
  width: ${({ $width }) => ($width ? $width : "unset")};
  @media screen and (max-width: 786px) {
    width: 100%;
  }
`;
