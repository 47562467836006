export const UploadSvg =
    (<svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_83_3363)">
            <rect width="95" height="95" rx="47.5" fill="white" fillOpacity="0.4" />
            <path d="M51.1577 44.4526H68.8971V50.7817H51.1577V69.1451H44.7394V50.7817H27V44.4526H44.7394V26H51.1577V44.4526Z" fill="white" />
        </g>
        <defs>
            <filter id="filter0_b_83_3363" x="-3" y="-3" width="101" height="101" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_83_3363" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_83_3363" result="shape" />
            </filter>
        </defs>
    </svg>)
export const RecordSvg = (
    <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_b_130_1983)">
            <rect width="95" height="95" rx="47.5" fill="white" fillOpacity="0.4" />
            <path d="M60.5 43.25V34.5C60.5 33.837 60.2366 33.2011 59.7678 32.7322C59.2989 32.2634 58.663 32 58 32H28C27.337 32 26.7011 32.2634 26.2322 32.7322C25.7634 33.2011 25.5 33.837 25.5 34.5V59.5C25.5 60.163 25.7634 60.7989 26.2322 61.2678C26.7011 61.7366 27.337 62 28 62H58C58.663 62 59.2989 61.7366 59.7678 61.2678C60.2366 60.7989 60.5 60.163 60.5 59.5V50.75L70.5 60.75V33.25L60.5 43.25Z" fill="white" />
        </g>
        <defs>
            <filter id="filter0_b_130_1983" x="-3" y="-3" width="101" height="101" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_130_1983" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_130_1983" result="shape" />
            </filter>
        </defs>
    </svg>)
