import React, { useState, useEffect, useRef, useContext } from "react";
import PageWrapper from "../../components/PageWrapper";
import styled, { keyframes } from "styled-components";
import Button from "../../components/Button";
import { ReactMediaRecorder } from "react-media-recorder";
import { useNavigate } from "react-router-dom";
import { HeaderStyled } from "../PressSelect";
import { VideoContext } from "../../App";
import VideoComponent from "../../components/VIdeo";

const MAX_RECORDING_TIME = 60;

const RecordVideo = () => {
  const { setIsVideo } = useContext(VideoContext);
  const [isRecording, setIsRecording] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [showCountdown, setShowCountdown] = useState(false);
  const recordRef = useRef(null);

  const navigate = useNavigate();

  const startCountdown = (startRecording, stopRecording) => {
    setCountdown(3);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(countdownInterval);
      startRecording(true);
      setIsRecording(true);
    }, 4000);
  };

  const VideoPreview = ({ stream }) => {
    useEffect(() => {
      if (recordRef.current && stream) {
        recordRef.current.srcObject = stream;
      }
    }, [stream]);
    return stream ? (
      <Video ref={recordRef} width={310} height={507} autoPlay />
    ) : null;
  };

  const handleRecordStart = (startRecording, stopRecording) => {
    if (countdown > 0) {
      startCountdown(startRecording, stopRecording);
      setShowCountdown(true);
    } else {
      setCountdown(3);
      startRecording(true);
      setIsRecording(true);
      setTimeout(() => {
        stopRecording();
        setIsRecording(false);
      }, MAX_RECORDING_TIME * 1000);
    }
  };

  const handleRecordStop = (stopRecording, mediaBlobUrl) => {
    stopRecording();
    setIsRecording(false);
    handleSave(mediaBlobUrl);
    if (!isRecording) {
      navigate("/select-video-topic");
    }
  };

  const handleSave = async (mediaBlobUrl) => {
    const videoBlob = await fetch(mediaBlobUrl).then((e) => e.blob());
    let name = Math.floor(Math.random() * 10) + 1;
    const videoFile = new File([videoBlob], `Video${name}.mp4`, {
      type: "video/mp4",
    });
    setIsVideo(videoFile);
  };

  const recordAgain = (clearBlobUrl) => {
    clearBlobUrl();
    window.location.reload();
  };

  return (
    <PageWrapper allCenter="column">
      <HeaderStyled>Recording</HeaderStyled>
      <Description>Please record a video of 60 Seconds or less.</Description>

      <ReactMediaRecorder
        video
        render={({
          status,
          startRecording,
          stopRecording,
          mediaBlobUrl,
          previewStream,
          clearBlobUrl,
        }) => (
          <>
            <Recording>
              <CountdownText show={showCountdown && countdown > 0}>
                {countdown}
              </CountdownText>

              {!mediaBlobUrl ? (
                <VideoPreview stream={previewStream} />
              ) : (
                <VideoComponent src={mediaBlobUrl} />
              )}
            </Recording>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "310px",
                marginTop: "1rem",
              }}
            >
              <RecordBtn
                onClick={() =>
                  status === "idle"
                    ? handleRecordStart(startRecording, stopRecording)
                    : handleRecordStop(stopRecording, mediaBlobUrl)
                }
              >
                <Button
                  btnText={
                    status === "idle"
                      ? "record"
                      : isRecording
                      ? "stop"
                      : "continue"
                  }
                  padding="12px 48px"
                  width="100%"
                  color={
                    isRecording &&
                    `linear-gradient(180deg, #F41A3C 0%, #FF4545 28.11%, #FF4545 73.4%, #F41A3C 100%),
                  linear-gradient(0deg, #FFFFFF, #FFFFFF)`
                  }
                />
              </RecordBtn>
              {mediaBlobUrl && (
                <RecordBtn onClick={() => recordAgain(clearBlobUrl)}>
                  <Button
                    btnText="Record Again"
                    padding="12px 48px"
                    width="100%"
                    color={`linear-gradient(180deg, #F41A3C 0%, #FF4545 28.11%, #FF4545 73.4%, #F41A3C 100%),
                  linear-gradient(0deg, #FFFFFF, #FFFFFF)`}
                  />
                </RecordBtn>
              )}
            </div>
          </>
        )}
      />
    </PageWrapper>
  );
};

export default RecordVideo;

export const VideoContainer = styled.div`
  position: relative;
  width: 310px;
  height: 507px;
  z-index: 100;
  border-radius: 20px;
  left: ${({ $right }) => $right && "15px"};
  background: black;
`;

const countdownAnimation = keyframes`
  0% {
    opacity: 0;
    font-size: 0vw;
  }
  100% {
    opacity: 1;
    font-size: 50px;
  }
`;

export const CountdownText = styled.p`
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 1s;
  animation: ${countdownAnimation} 1s forwards;
  font-size: 50px;
  color: #ffffff;
  display: ${({ show }) => (show ? "block" : "none")};
`;

export const CustomControls = styled.div`
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
`;

export const PlayPauseButton = styled.div`
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
`;

export const Recording = styled.div`
  width: 310px;
  height: 507px;
  border-radius: 20px;
  background: black;
`;

export const Description = styled.p`
  margin-top: -10px;
  text-align: center;
  color: white;
`;

export const Video = styled.video`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-size: cover;
  object-fit: cover;
`;

export const RecordBtn = styled.div`
  bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
