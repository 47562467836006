import styled from "styled-components";

export const MainWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background: url("/assests/images/WrapperImage.png") no-repeat fixed center;
  background-size: cover;
`;

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: ${({ $allCenter }) => ($allCenter ? $allCenter : "row")};
  max-width: 1000px;
  background-color: ${({ $noContainer }) =>
    $noContainer ? "none" : "rgba(255, 255, 255, 0.3)"};
  /* opacity: 0.5; */
  padding: 1rem 1rem;
  z-index: 10;
  border: ${({ $noContainer }) =>
    $noContainer ? "none" : "1px solid #ffffff"};
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1002px) {
    width: fit-content;
    height:fit-content ;
  }
`;

export const ImageEffect = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const OpacityEffect = styled.div`
  opacity: 0.9;
`;
export const PreviousButton = styled.button`
  position: absolute;
  top: 35px;
  left: 60px;
  border: none;
  background: none;
  cursor: pointer;
  z-index: 1111;
`;
