import styled from "styled-components";

export const BrickBlueBlock = styled.div`
  position: relative;
  background: url("/assests/images/brickblue.png") center center no-repeat;
  object-fit: cover;
  width: fit-content;
  display: flex;
  justify-self: center;
  z-index: 1000;
  height: 100%;
`;
export const BlueBackgroundBlock = styled.div`
  position: relative;
  background: url("/assests/images/blueBackground.png") top center no-repeat;
  object-fit: cover;
  width: fit-content;
  display: flex;
  justify-self: center;
  height: 100%;
`;
export const FrameImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 111;
`;
export const IconBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  & > span > svg > g > rect:hover {
    fill: #7b7878;
  }
`;

export const Content = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  padding: 10px 5px 13px;
  z-index: 1000;
`;

export const FrameText = styled.p`
  font-family: ArcadeClassic;
  font-size: 21px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.3em;
  text-align: center;
  text-transform: uppercase;
  margin: -0 0 7rem 0;
  padding: 0 2rem;
`;
export const VideoFormatText = styled.p`
  font-family: ArcadeClassic;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  color: #d0fff3;
`;

export const FormContent = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  z-index: 1000;
  align-items: unset;
  color: white;
  padding: 4rem 2.5rem 0 2.5rem;
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const GridStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;
