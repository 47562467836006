import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAfIHWvZOkEJEjxN1zv-x_FGvomAkoYY34",
  authDomain: "fir-video-website-bddb4.firebaseapp.com",
  projectId: "fir-video-website-bddb4",
  storageBucket: "fir-video-website-bddb4.appspot.com",
  messagingSenderId: "427689198745",
  appId: "1:427689198745:web:56da86ec589d5598776408",
  measurementId: "G-QSBP52EN79",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
