import React from "react";
import {
  ContentContainer,
  ImageEffect,
  MainWrapper,
  OpacityEffect,
  PreviousButton,
} from "./style";
import { useLocation, useNavigate } from "react-router-dom";

const PageWrapper = ({ children, noContainer, allCenter }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <MainWrapper>
      <ImageEffect></ImageEffect>
      {location.pathname !== "/" && (
        <PreviousButton onClick={() => navigate(-1)}>
          <img src={"/assests/svgs/arrow-left.svg"} alt="back" />
        </PreviousButton>
      )}
      <ContentContainer $noContainer={noContainer} $allCenter={allCenter}>
        <OpacityEffect></OpacityEffect>
        {children}
      </ContentContainer>
    </MainWrapper>
  );
};

export default PageWrapper;
